jQuery(document).ready(function($){

/**
 * Estado das checkboxes detail quando se faz history.back
 */

var estadoCheckboxChep = $('#checkbox-chep');
if (estadoCheckboxChep.is(":checked")) {

	if (!$('#chepDetail').hasClass('show')) {
		$('#chepDetail').addClass('show');
	}
}

var estadoCheckboxLpr = $('#checkbox-lpr');
if (estadoCheckboxLpr.is(":checked")) {

	if (!$('#lprDetail').hasClass('show')) {
		$('#lprDetail').addClass('show');
	}
}

/**
 * Fim Estado das checkboxes detail quando se faz history.back
 */

/**
 * Buscar entidades de acordo com tipo de mov e fornecedor
 */

$('#tipo-mov').on('change', function(){

	var tipo_mov = $(this).val();
	var fornecedor = $('#fornecedor').val();

	if (tipo_mov) {
		$.getJSON({
			type: 'post',
			url: 'inc_admin/ajax_buscar_entidade.php',
			//The url is relative to the current page, NOT to the .js file
			data: {
				tipo_mov: tipo_mov,
				fornecedor: fornecedor
			},
			success: function(response){

				var entidade_actual = $('#entidade').val();
				$('#entidade').html('');
				$('#ent-id').val('');
				var new_option = '<option class="nome-option" data-id="" value=""> Seleccionar</option>';

				$.each(response, function(key, value){
					nome = value['nome'];
					id = value['id'];
					activo = value['activo'];

					if (activo === 1) {
						activo = 'visivel';
					} else { 
						activo = 'hidden' 
					}

					if (nome === entidade_actual) {
						estado_select = 'selected';
						$('#ent-id').val(id);
					} else {
						estado_select = '';
					}

					new_option += '<option class="'+activo+'" data-id="'+id+
								'" value="'+nome+'"'+estado_select+'>'+nome+'</option>';

				});

				$('#entidade').append(new_option);
				
			}

		});
	}
	else{
		
		return false;
	}
});

/**
 * Fim buscar entidades de acordo com tipo de mov e fornecedor
 */

/**
 * Colocar id-ent coreespondente à entidade que se seleccionou
 */

$('#entidade').on('change', function(){
	id = $('#entidade option:selected').data('id');
	$('#ent-id').val(id);
});

/**
 * Fim Colocar id-ent coreespondente à entidade que se seleccionou
 */



/**
 * Colocar id-pal coreespondente à entidade que se seleccionou
 */

$('#palete').on('change', function(){
	id_pal = $('#palete option:selected').data('id-pal');
	$('#pal-id').val(id_pal);
});

/**
 * Fim Colocar id-pal coreespondente à entidade que se seleccionou
 */


$(document).on('click', '.gerastock-checkbox', function(){

    gera_stock = $(this).closest('td').find('.gera-stock');

    if ($(this).is(':checked')) {

        gera_stock.val('1');
    }
    else{
        gera_stock.val('');
    }
    
});

//utilizadores
$(document).on('click', '.ch-click', function(){

    ch_value = $(this).closest('td').find('.ch-value');

    if ($(this).is(':checked')) {

        ch_value.val('1');
    }
    else{
        ch_value.val('');
    }
    
});


/**
 * Copiar factura (ao escrever) para todos os campos + se já tiver escrito a factura e
 * só depois colocar o 'visto', vai buscar o valor da factura em 
 * páginas PORCONFERIR
 */
$('#factura-global').on('keyup', function(){

	var factura_num = ($(this).val());

	$('.conf-cb').each(function(){
		if ($(this).is(':checked')) {
			var factura_ind = $(this).closest('tr').find('.factura-ind');
			factura_ind.val(factura_num);
		}
	});
});

$('.conf-cb').on('change', function(){

	var factura_num = $('#factura-global').val();
	var factura_ind = $(this).closest('tr').find('.factura-ind');
	
	if ($(this).is(':checked')) {
		factura_ind.val(factura_num);
	}
	else{
		factura_ind.val('');
	}

});


/**
 * Fim Copiar factura para todos os campos
 */


/**
 * Retirar nome da factura quando se tira o 'visto'
 * Volta a pôr o nome da factura se se voltar a pôr o 'visto'
 * páginas CONFERIDOS
 */

$('.conf-cb-conf').on('change', function(){

	var factura_ind = $(this).closest('tr').find('.factura-ind-conf');
	var valor_antigo = $(this).closest('tr').find('.factura-ind-original').val();
	
	if (!$(this).is(':checked')) {
		factura_ind.val('');
	}
	else{
		factura_ind.val(valor_antigo);
	}

});


/**
 * Fim Retirar nome da factura quando se tira o 'visto'
 * Volta a pôr o nome da factura se se voltar a pôr o 'visto'
 */

/**
 * Esconder e mostrar sidebar
 */
$('.tog').on('click', function(){
	margin_left = $(this).parent().css('margin-left');

	if (margin_left === '-175px') {
		$(this).parent().css('margin-left', '0px');
	}
	else if(margin_left === '0px'){
		$(this).parent().css('margin-left', '-175px');
	}
});
/**
 * Fim Esconder e mostrar sidebar
 */



}); // jQuery