$(function(){

/**
 * Verificar campos das linhas
 */
$(document).on('click', '#gravar-folhakms', function(){


	$('.avisos').html('');
	let erro = '';
	let texto = '';
	
    let total_kms = $('#total-kms').val();

    if (total_kms <= 0) {
        erro = 'sim';
        texto = 'O total de Kms tem de ser superior a 0.';
        avisos(texto);
    }

    $('.entidade-id').each(function(){

        let entidade = $(this).val();

        if (entidade && entidade > 0) {
            
            let servico = $(this).parent().parent().find('.servico-id').val();
            let percurso = $(this).parent().parent().find('.percurso-id').val();
            let percurso_kms = $(this).parent().parent().find('.percurso-kms').val();

            if(!servico){
                $(this).parent().parent().find('.servico-nome').addClass('errorvazio');
                
                erro = 'sim';
                texto = 'Tem de ser seleccionado um serviço.';
                avisos(texto);
            }
            if(!percurso){
                $(this).parent().parent().find('.percurso-nome').addClass('errorvazio');

                erro = 'sim';
                texto = 'Tem de ser seleccionado um percurso.';
                avisos(texto);
            }
            if(!percurso_kms || isNaN(percurso_kms) || percurso_kms < 50){
                $(this).parent().parent().find('.percurso-kms').addClass('errorvazio');

                erro = 'sim';
                texto = 'O campo - Kms - tem de ser númerico e maior que 50.';
                avisos(texto);
            }
        }

    
    });

    $('.percurso-kms').each(function(){
        let kms = $(this).val();

        if (kms && kms > 0) {
            let entidade = $(this).parent().parent().find('.entidade-id').val();
console.log(entidade);
            if(!entidade || entidade == 0){
                $(this).parent().parent().find('.entidade-nome').addClass('errorvazio');
                
                erro = 'sim';
                texto = 'Tem de ser seleccionada uma entidade.';
                avisos(texto);
            }
        }
    });

    /**
	 * Se houver algum erro nas verificações anteriores retorna falso
	 */
	if (erro) {
        return false;
    }

});

/**
 * Tirar aviso de erro no input
 */
$(document).on('focus', '.entidade-nome, .servico-nome, .percurso-kms', function(){

    $(this).removeClass('errorvazio');
});

$(document).on('focus', '.percurso-nome', function(){

    $(this).removeClass('errorvazio');
    $(this).parent().parent().find('.percurso-kms').removeClass('errorvazio');
});

/**
 * Verificar campos das linhas
 */


/**
 * Verificar se nome da entidade já existe
 */
$('#entidade-nome').on('keyup', function(){

	var id = parseInt($('#entidade-id').val());
	var var_nome = $('#entidade-nome').val();
	var campo_bd = 'nome';
	var tabela = 'entidades';

	if (var_nome) {
		$.ajax({
			type: 'post',
			url: 'inc_admin/check_entidade_nome.php',
			data: {
				id: id,
				var_nome: var_nome,
				campo_bd: campo_bd,
				tabela: tabela
			},
			success: function(response){
				$('#entidade-nome-status').html(response);

				if (response == 'ok') {
					return true;	
				} 
				else {
					return false
				}
			}

		});
	}
	else{
		$('#entidade-nome-status').html('');
		return false;
	}
});
/**
 * Fim verificar se nome da entidade já existe
 */


/**
 * Verificar se nif da entidade já existe
 */
$('#entidade-nif').on('keyup', function(){

	var id = $('#entidade-id').val();
	var var_nome = $('#entidade-nif').val();
	var campo_bd = 'nif';
	var tabela = 'entidades';

	if (var_nome) {
		$.ajax({
			type: 'post',
			url: 'inc_admin/check_entidade_nome.php',
			data: {
				id: id,
				var_nome: var_nome,
				campo_bd: campo_bd,
				tabela: tabela
			},
			success: function(response){
				$('#entidade-nif-status').html(response);

				if (response == 'ok') {
					return true;	
				} 
				else {
					return false
				}
			}

		});
	}
	else{
		$('#entidade-nif-status').html('');
		return false;
	}
});
/**
 * Fim verificar se nif da entidade já existe
 */


/**
 * Verificar se nome do percurso já existe
 */
$('#percurso-nome').on('keyup', function(){

	var id = parseInt($('#percurso-id').val());
	var var_nome = $('#percurso-nome').val();
	var campo_bd = 'local_nome';
	var tabela = 'percursos';

	if (var_nome) {
		$.ajax({
			type: 'post',
			url: 'inc_admin/check_entidade_nome.php',
			data: {
				id: id,
				var_nome: var_nome,
				campo_bd: campo_bd,
				tabela: tabela
			},
			success: function(response){
				$('#percurso-nome-status').html(response);

				if (response == 'ok') {
					return true;	
				} 
				else {
					return false
				}
			}

		});
	}
	else{
		$('#percurso-nome-status').html('');
		return false;
	}
});
/**
 * Fim verificar se nome do percurso já existe
 */

/**
 * Verificar se nome do servico já existe
 */
$('#servico-nome').on('keyup', function(){

	var id = parseInt($('#servico-id').val());
	var var_nome = $('#servico-nome').val();
	var campo_bd = 'servico';
	var tabela = 'servicos';

	if (var_nome) {
		$.ajax({
			type: 'post',
			url: 'inc_admin/check_entidade_nome.php',
			data: {
				id: id,
				var_nome: var_nome,
				campo_bd: campo_bd,
				tabela: tabela
			},
			success: function(response){
				$('#servico-nome-status').html(response);

				if (response == 'ok') {
					return true;	
				} 
				else {
					return false
				}
			}

		});
	}
	else{
		$('#servico-nome-status').html('');
		return false;
	}
});
/**
 * Fim verificar se nome do percurso já existe
 */














/**
 * Tirar aviso de erro no input
 */
$(document).on('focus', '.input-control', function(){

    $(this).removeClass('errorvazio');
});

/**
 * Verificações ao gravar entidade
 */
$('#gravar-entidade').on('click', function(){

	$('.avisos').html('');

	let erro = '';
	let texto = '';
	let nome = $('#entidade-nome').val();
	let entidade_nome_status = $('#entidade-nome-status').html();
	let nif = $('#entidade-nif').val();
	let entidade_nif_status = $('#entidade-nif-status').html();
	//console.log(entidade_nome_status);

	/**
	 * Campo nome
	 */
	if (!nome) {
		erro = 'sim';
		$('#entidade-nome').addClass('errorvazio');
		$('#entidade-nome-status').html('Campo obrigatório');

		texto = 'Preencha o campo - nome.';

		avisos(texto);
	}
	else if(nome && entidade_nome_status != ''){

		if (entidade_nome_status != 'ok') {

			erro = 'sim';
			$('#entidade-nome').addClass('errorvazio');

			texto = nome + ' já existe.';

			avisos(texto);
		}
	}
	/**
	 * Fim campo nome
	 */

	/**
	 * Campo nif
	 */
	if (nif && entidade_nif_status != '') {

		if (entidade_nif_status != 'ok') {

			erro = 'sim';
			$('#entidade-nif').addClass('errorvazio');

			texto = nif + ' já existe.';

			avisos(texto);
		}
	}
	/**
	 * Fim campo nif
	 */
	


	/**
	 * Se houver algum erro nas verificações anteriores retorna falso
	 */
	if (erro) {
        return false;
    }


});
/**
 * Fim verificações ao gravar entidade
 */



/**
 * Verificações ao gravar serviço 
 */
$('#gravar-servico').on('click', function(){

	$('.avisos').html('');

	let erro = '';
	let texto = '';
	let nome = $('#servico-nome').val();
    let servico_nome_status = $('#servico-nome-status').html();

	//console.log(fornecedor);

	/**
	 * Campo nome
	 */
	if (!nome) {
		erro = 'sim';
		$('#servico-nome').addClass('errorvazio');
		$('#servico-nome-status').html('Campo obrigatório');

		texto = 'Preencha o campo - nome.';

		avisos(texto);
	}
	else if(nome && servico_nome_status != ''){

		if (servico_nome_status != 'ok') {

			erro = 'sim';
			$('#servico-nome').addClass('errorvazio');

			texto = nome + ' já existe.';

			avisos(texto);
		}
	}
	/**
	 * Fim campo nome
	 */

	/**
	 * Se houver algum erro nas verificações anteriores retorna falso
	 */
	if (erro) {
        return false;
    }


});
/**
 * Fim verificações ao gravar serviço
 */

/**
 * Verificações ao gravar percurso 
 */
$('#gravar-percurso').on('click', function(){
console.log('dass');
	$('.avisos').html('');

	let erro = '';
	let texto = '';
	let nome = $('#percurso-nome').val();
    let percurso_nome_status = $('#percurso-nome-status').html();

	//console.log(fornecedor);

	/**
	 * Campo nome
	 */
	if (!nome) {
		erro = 'sim';
		$('#percurso-nome').addClass('errorvazio');
		$('#percurso-nome-status').html('Campo obrigatório');

		texto = 'Preencha o campo - nome.';

		avisos(texto);
	}
	else if(nome && percurso_nome_status != ''){

		if (percurso_nome_status != 'ok') {

			erro = 'sim';
			$('#percurso-nome').addClass('errorvazio');

			texto = nome + ' já existe.';

			avisos(texto);
		}
	}
	/**
	 * Fim campo nome
	 */

	/**
	 * Se houver algum erro nas verificações anteriores retorna falso
	 */
	if (erro) {
        return false;
    }


});
/**
 * Fim verificações ao gravar percurso
 */





}); //jQuery

/**
 * Verificações ao criar folha
 */


function criarDatasCheckCampos(){


	$('.avisos').html('');

	let erro = '';
	let texto = '';
	let mes = $('#select-mes').val();
	let ano = $('#input-ano').val();
	//console.log(entidade_nome_status);
//console.log('mes ' + mes);
	/** 
	 * Campo mes
	 */
	if (!mes) {
		erro = 'sim';
        
		$('#select-mes').addClass('errorvazio');

		texto = 'Seleccione o campo - Mês';

		avisos(texto);
	}
	/**
	 * Fim campo mes
	 */
	
	/**
	 * Campo ano
	 */
	if (!ano) {
		erro = 'sim';
		$('#input-ano').addClass('errorvazio');

		texto = 'Preencha o campo - Ano';

		avisos(texto);
	}

	if (isNaN(ano)) {
		erro = 'sim';
		$('#select-ano').addClass('errorvazio');

		texto = 'O campo qtd real tem de ser numérico e é obrigatório.';

		avisos(texto);
	}
	/**
	 * Fim campo ano
	 */

	/**
	 * Se houver algum erro nas verificações anteriores retorna falso
	 */
	if (erro) {
        return false;
    }
    else{
        return true;
    }


//});
}





/**
 * Fim verificações ao criar folha
 */



function avisos(texto){

	$('.avisos').append('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
						 texto +
						 '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'+
                         '<span aria-hidden="true">&times;</span>'+
                         '</button>'+
                         '</div>'
			);

}