$(function(){

$('.cb-entidade-nome').on('change', function(){

	
	let entidadeId = $(this).parent().find('.cb-entidade-id');
	
	if ($(this).is(':checked')) {

        let id = $(this).data('id');
		entidadeId.val(id);
	}
	else{
		entidadeId.val('');
	}

});

$('#bg-color').on('keyup change', function(){

    let bg_color = $(this).val();

    $(this).css('backgroundColor', bg_color);

});




//teste mask js
// var options = {
//     onChange: function(cep){
//         reverse: true,
//         console.log('cep changed', cep);
//     }
// }

// $('.total-kms-ent').mask('000.000.000.000.000,00', {
//     reverse: true,
//     onChange: function(options){
//         console.log('dass');
//     }
// });
//$('#numero').mask('000.000.000.000.000,00', options);



});