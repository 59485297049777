$(function(){  //novo método para document.ready


 
//Para poder usar luxon
let DateTime = luxon.DateTime;

$('#criar-datas').on("click", function(){

    $('.result').html('');

    let status_check_campos = criarDatasCheckCampos();

    if(status_check_campos){

        let erro = '';
        let texto = '';
        let id_geral = $('#id-geral').val();
        let mes = $('#select-mes').val();
        let ano = $('#input-ano').val();
    
        /**
         * Verificar se já existe
         */

        if(!id_geral){

            $.ajax({
                type: 'post',
                url: 'inc_admin/check_folha.php',
                data: {
                    id_geral: id_geral,
                    var_mes: mes,
                    var_ano: ano
                },
                success: function(response){
                    
                    if (response == 'ok') {
                        erro = '';
                        criarLinhas();
                        
                    } 
                    else {

                        
                        mes = parseInt(mes);
                        let mes_ext = DateTime.local(mes);
                        
                        mes_ext = mes_ext.monthLong;
                        erro = 'sim';
                        $('#select-mes').addClass('errorvazio');
                        //$('#input-ano').addClass('errorvazio');

                        texto = 'A folha de ' + mes_ext.substr(0,1).toUpperCase()+mes_ext.substr(1) + ' de ' + ano + ' já existe';
                        avisos(texto);
                        
                    }

                }

            }); // ajax
        } // if !id_geral
    } // if !status_check

}); // criar datas

function criarLinhas(){

    entidadesActivas = buscarEntidades();

    //console.log(percursosActivos);
    
    
    let mes = parseInt($('#select-mes').val());
    let ano = parseInt($('#input-ano').val());
    let dt = DateTime.local(ano, mes);
    //console.log(dt);
    let duracaoMes = dt.daysInMonth;

    for(i=0; i<duracaoMes; i++){

        let novoDia = dt.plus({days: i});

        let dia = novoDia.day;
        let diaExtenso = novoDia.weekdayShort;
        //console.log(diaExtenso);
        //console.log(dia);
        if (diaExtenso == 'sábado' || diaExtenso == 'domingo') {
            classe = 'fim-semana';
        }
        else{
            classe = '';
        }

        $('.result').append(`
            <tr class="${classe}">
                <td class="hidden"><input type="text" name="id_linha[]" readonly/></td>
                <td><input type="text" name="dia[]" value="${dia}" readonly/></td>
                <td><input type="text" name="dia_ext[]" value="${diaExtenso}" readonly/></td>
                <td class="hidden"><input class="entidade-id" name="entidade_id[]" type="text" readonly/></td>
                <td>
                    <select class="entidade-nome" name="entidade_nome[]">
                        <option>---</option>
                        ${entidadesActivas.map(item => `<option value="${item.nome}" data-id="${item.id}">
                                                            ${item.nome}
                                                        </option>`).join('')}
                    </select> 
                </td>
                <td class="hidden"><input class="servico-id" name="servico_id[]" type="text" readonly/></td>
                <td>
                    <select class="servico-nome" name="servico_nome[]">
                        <option>---</option>
                        
                    </select>
                </td> 
                <td class="hidden"><input class="percurso-id" name="percurso_id[]" type="text" readonly/></td>
                <td>
                    <select class="percurso-nome" name="percurso_nome[]"> 
                        <option>---</option>
                        
                    </select>
                </td>

                <td><input class="percurso-kms" name="percurso_kms[]" type="text"/></td>
            </tr>
        `);
        
    } // for i<duracao_mes

} // function criarLinhas()
  
function buscarEntidades(){

    var entidades = [];
 
    $('.entidades-activas p').each(function(){

        id = $(this).data('id');
        ent = $(this).text();
        entidades.push({
            id: id,
            nome:ent
        });
    });
//console.log(entidades);
    return entidades;
}


$(document).on('change', '.entidade-nome', function(){

    let entidade_id = $('option:selected', this).data('id');
    $(this).parent().parent().find('.entidade-id').val(entidade_id);

    let servico_select = $(this).parent().parent().find('.servico-nome');
    $(this).parent().parent().find('.servico-id').val('');

    let percurso_select = $(this).parent().parent().find('.percurso-nome');
    $(this).parent().parent().find('.percurso-id').val('');
    let percurso_kms = $(this).parent().parent().find('.percurso-kms');

    if(entidade_id){

        $.ajax({
            type: "POST",
            url: 'inc_admin/ajax_buscar_servicos.php',
            dataType: 'json',
            data:{
                id: entidade_id
            },
            success: function(response_serv){
                
                //console.log(tamanho);
                if(response_serv.length){
                    new_serv_option = '<option>-- Seleccionar Serviço</option>';
                }
                else{
                    new_serv_option = '<option>Entidade sem serviços relacionados</option>';
                }
                
                new_serv_option += response_serv.map(item => `<option value="${item.servico}" data-id="${item.id}">
                                                        ${item.servico}
                                                        </option>`);
                
                servico_select.html(new_serv_option);
            }

        });

        
        $(this).parent().parent().find('.percurso-id').val('');
        let inicio_percurso = $('#inicio-percurso').text();
        percurso_kms.val('');

        $.ajax({
            type: "POST",
            url: 'inc_admin/ajax_buscar_percursos.php',
            dataType: 'json',
            data:{
                id: entidade_id
            },
            success: function(response_perc){
                
                //console.log(tamanho);
                if(response_perc.length){
                    new_perc_option = '<option value="0">-- Seleccionar percurso</option>';
                }
                else{
                    new_perc_option = '<option value="0">Entidade sem percursos relacionados</option>';
                }
                
                new_perc_option += response_perc.map(item => `<option value="${inicio_percurso} - ${item.nome} - ${inicio_percurso}" data-id="${item.id}">${inicio_percurso} - ${item.nome} - ${inicio_percurso}</option>`);
                
                percurso_select.html(new_perc_option);
            }

        });
    
    }
    else{
        servico_select.html('<option>---</option>');
        percurso_select.html('<option>---</option>');
        percurso_kms.val('');
    }

    calcularKms();
	
});

$(document).on('change', '.servico-nome', function(){

    servico_id = $('option:selected', this).data('id');
    $(this).parent().parent().find('.servico-id').val(servico_id);
	
});

$(document).on('change', '.percurso-nome', function(){

    percurso_id = $('option:selected', this).data('id');
    $(this).parent().parent().find('.percurso-id').val(percurso_id);
    input_kms = $(this).parent().parent().find('.percurso-kms');


    $.ajax({
        type: "POST",
        url: 'inc_admin/ajax_buscar_kms.php',
        dataType: 'json',
        data:{
            id: percurso_id
        },
        success: function(response){
            //console.log($(this));
            num_kms = response * 2;
            input_kms.val(num_kms);

            calcularKms();

        }
    }); 

    

});

$(document).on('keyup', '.percurso-kms', function(){
    calcularKms();
});

function calcularKms(){

    entidades = buscarEntidades();
    const valor_por_km = parseFloat($('#valor-km').val());
    let total_kms = 0;
    let total_euros = 0;

    $('.total-kms-ent, .total-euros-ent, .dif-kms-ent, .dif-euros-ent').each(function(){
        $(this).val('');
    });
    

    for (let i = 0; i < entidades.length; i++) {

        let entidade_id = entidades[i].id;
        let entidade_kms = 0;
        let entidade_euros = 0;
        let ent_id = 0;

        $('.percurso-kms').each(function(){

            ent_id = $(this).parent().parent().find('.entidade-id').val();
            let kms = parseFloat($(this).val());
            let valor_euros = 0;

            if(kms && entidade_id == ent_id){
                total_kms += kms;
                entidade_kms += kms;

                valor_euros = valor_por_km * kms;
                total_euros += valor_euros;
                entidade_euros += valor_euros;

            }

        });


        let ref_kms = $('#ref-kms-'+entidade_id).val();
        ref_kms = ref_kms.replace('.', '');
        ref_kms = parseFloat(ref_kms);
        //console.log(ref_kms);
        let diferenca_kms =  entidade_kms - ref_kms;


        //Colocar valores
        entidade_kms = number_format(entidade_kms, 2, ',', '.');
        $('#total-kms-'+entidade_id).val(entidade_kms);
        
        diferenca_kms = number_format(diferenca_kms, 2, ',', '.');
        $('#dif-kms-'+entidade_id).val(diferenca_kms);
 

        

        // Euros entidade
        let ref_euros = $('#ref-euros-'+entidade_id).val();
        ref_euros = ref_euros.replace('.', '');
        ref_euros = parseFloat(ref_euros);
        let diferenca_euros = entidade_euros - ref_euros;

        //Colocar valorres
        entidade_euros = number_format(entidade_euros, 2, ',', '.');
        $('#total-euros-'+entidade_id).val(entidade_euros);

        diferenca_euros = number_format(diferenca_euros, 2, ',', '.');
        $('#dif-euros-'+entidade_id).val(diferenca_euros);

    }

    $('#total-kms').val(total_kms.toFixed(2));
    total_kms_front = number_format(total_kms, 2, ',', '.');
    $('#total-kms-front').val(total_kms_front);

    $('#total-euros').val(total_euros.toFixed(2));
    total_euros_front = number_format(total_euros, 2, ',', '.');
    $('#total-euros-front').val(total_euros_front);
    

    
}



	



}); //jQuery

function number_format (number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? '.' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? ',' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}